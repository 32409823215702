<template>
  <v-container fluid>
    <v-card class="card card-custom card-stretch gutter-b" v-if="getreservationLoading==false">
      <!-- Header -->
      <v-card-title class="m-5">
        <h3 class="font-weight-bolder text-primary">Table Reservations</h3>
      </v-card-title>

      <!-- Data Table -->
      <v-card-subtitle>
        <v-data-table
          :headers="headers"
          :items="form.reservations.data"
          item-key="id"
          class="elevation-1"
          :items-per-page="itemsPerPage"
          :page="currentPage"
          :footer-props="{ 'items-per-page-options': [] }"
          hide-default-footer
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td v-for="header in headers" :key="header.value">
                  {{ item[header.value] }}
                </td>
                <td>
                  <span class="d-flex">
                    <template v-if="item.status === 'Pending'">
                      <v-btn  v-if=" $hasPermission('manage reservations') "  @click="openConfirmationDialog(item.id, 'Reserved')" class="mr-1 bg-primary text-light" small>Accept</v-btn>
                      <v-btn  v-if=" $hasPermission('manage reservations') "  @click="openConfirmationDialog(item.id, 'Rejected')" class="bg-danger text-light" small>Decline</v-btn>
                    </template>
                    <template v-else>
                      <v-btn :class="item.status === 'Reserved' ? 'bg-primary text-light' : 'bg-danger text-light'" small>
                        {{ item.status }}
                      </v-btn>
                    </template>
                    <!-- Eye Icon to View Details -->
                    <v-btn @click="openDetailsModal(item)" icon class="ml-2">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-subtitle>

      <!-- Pagination Controls -->
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        @input="loadReservations"
      ></v-pagination>
    </v-card>

    <!-- Reservation Details Modal -->
    <v-dialog v-model="detailsDialog.visible" max-width="600">
      <v-card v-if="selectedReservation">
        <v-card-title class="headline">Reservation Details</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <p><strong>Full Name:</strong> {{ selectedReservation.full_name }}</p>
              <p><strong>Email:</strong> {{ selectedReservation.email }}</p>
              <p><strong>Phone:</strong> {{ selectedReservation.phone }}</p>
            </v-col>
            <v-col cols="12" md="6">
              <p><strong>Date:</strong> {{ selectedReservation.date }}</p>
              <p><strong>Start Time:</strong> {{ selectedReservation.start_time }}</p>
              <p><strong>End Time:</strong> {{ selectedReservation.end_time }}</p>
              <p><strong>Number of People:</strong> {{ selectedReservation.no_of_people }}</p>
            </v-col>
          </v-row>
          <v-row v-if="selectedReservation.table">
            <v-col cols="12">
              <p><strong>Table:</strong> {{ selectedReservation.table.title }}</p>
              <p><strong>Capacity:</strong> {{ selectedReservation.table.seating_capacity }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p><strong>Status:</strong>
                <v-chip :color="getStatusColor(selectedReservation.status)" dark>
                  {{ selectedReservation.status }}
                </v-chip>
              </p>
            </v-col>
            <v-col cols="12" sm="6">
            <p class="d-flex">
              <strong>Payment Status:</strong>
              <v-chip :color="selectedReservation.xonder_transaction_id ? 'success' : 'error'" dark>
                {{ selectedReservation.xonder_transaction_id ? "Advance Paid Online" : "Not Paid" }}
              </v-chip>
            </p>
          </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="detailsDialog.visible = false" color="primary" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog.visible" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Action</v-card-title>
        <v-card-subtitle>
          Are you sure you want to change the status to "{{ dialog.status }}"?
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  v-if=" $hasPermission('manage reservations') "  @click="confirmChangeStatus" class="bg-primary text-light font-poppins">Yes</v-btn>
          <v-btn @click="dialog.visible = false" text>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { BUSINESS_RESERVATIONS_UPDATE, BUSINESS_RESERVATIONS } from "@/core/services/store/config.module";

export default {
  name: "ReservationsTableWidget",
  computed: {
    ...mapGetters(["currentUser","getreservationLoading"]),
    totalPages() {
      return Math.ceil(this.form.reservations.total / this.itemsPerPage);
    },
  },
  data() {
    return {
      form: {
        reservations: {
          data: [],
          current_page: 1,
          last_page: 1,
          total: 0,
        },
      },
      headers: [
        { text: 'Full Name', value: 'full_name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Contact', value: 'phone', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Start Time', value: 'start_time', sortable: false },
        // { text: 'End Time', value: 'end_time', sortable: false },
        // { text: 'Number of People', value: 'no_of_people', sortable: false },
        // { text: 'Notes', value: 'notes', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
      dialog: {
        visible: false,
        id: null,
        status: '',
      },
      detailsDialog: {
        visible: false,
      },
      selectedReservation: null,
      currentPage: 1,
      itemsPerPage: 10,
      loading: false,
    };
  },
  mounted() {
    this.loadReservations();
  },
  methods: {
    loadReservations(page = 1) {
      this.loading = true;
      this.$store
        .dispatch(BUSINESS_RESERVATIONS, { page, per_page: this.itemsPerPage })
        .then((response) => {
          this.form.reservations = response;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    openConfirmationDialog(id, status) {
      this.dialog.visible = true;
      this.dialog.id = id;
      this.dialog.status = status;
    },
    confirmChangeStatus() {
      const { id, status } = this.dialog;
      this.dialog.visible = false;
      this.$store
        .dispatch(BUSINESS_RESERVATIONS_UPDATE, { id, status })
        .then(() => {
          this.loadReservations(this.currentPage);
          this.$emit('update-stats');

        })
        .catch(console.error);
    },
    openDetailsModal(reservation) {
      this.selectedReservation = reservation;
      this.detailsDialog.visible = true;
    },
    getStatusColor(status) {
      switch (status) {
        case 'Reserved': return 'green';
        case 'Pending': return 'orange';
        case 'Rejected': return 'red';
        default: return 'grey';
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles as needed */
</style>
